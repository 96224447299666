import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'ClickToggle';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        this.$toggler = this.$el.find('.js-toggler')
    }

    init() {
        this.$toggler.on(EVENT.CLICK, () => {
            this.$el.toggleClass('-active')
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
