/* jshint esnext: true */
import { APP_NAME } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Banner';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    MOUSEENTER: `mouseenter.${EVENT_NAMESPACE}`,
    MOUSELEAVE: `mouseleave.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties
        this.$list = $('.js-list', this.$el);
    }

    init() {
        // Set events and such
        this.tl = new TimelineMax({ repeat: -1 });
        this.tl.to(this.$list, 10, {
            x: '-100%',
            force3D: true,
            ease: Power0.easeNone
        });
        this.tl.to(this.$list, 0, {
            x: 0,
            force3D: true,
            ease: Power0.easeNone
        });

        // this.$el.on(EVENT.MOUSEENTER,() => {
        //     this.tl.timeScale(0.8);
        // });
        // this.$el.on(EVENT.MOUSELEAVE,() => {
        //     this.tl.timeScale(1);
        // });
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
