/* jshint esnext: true */
import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'AnimLottie';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

const PATH_TO_MOTION_FILES = 'wp-content/themes/ezra/library/assets/data/lottie/'
const OPTIONS_DEFAULT = {
    loop: false,
    autoplay: true,
    animType: 'svg',
    speed: 1
}

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.$container = this.$el
        this.container = this.$container[0]
        this.fileName = options['file-name']
        this.options = options
    }

    init() {
        this.lottiePromise = this.loadAnimationByName(this.fileName).then(data => {
            if(this.toDestroy) return

            let lottieAnim = lottie.loadAnimation({
                container: this.container,
                animType: this.options.animType != undefined ? this.options.animType : OPTIONS_DEFAULT.animType,
                loop: this.options.loop         != undefined ? this.options.loop : OPTIONS_DEFAULT.loop,
                autoplay: false,
                animationData: data
            })

            lottieAnim.setSpeed(this.options.speed != undefined ? this.options.speed : OPTIONS_DEFAULT.speed)

            this.animation = {
                lottieAnim,
                data
            }

            let lottiePromise = new Promise((resolve, reject) => {
                lottieAnim.addEventListener('DOMLoaded', () => {
                    resolve()

                    if(this.options.autoplay != undefined ? this.options.autoplay : OPTIONS_DEFAULT.autoplay) lottieAnim.play()

                    TweenMax.fromTo(this.container, 1, { opacity: 0 }, { opacity: 1 });

                    window.lottiePromises.splice(window.lottiePromises.indexOf(this.lottiePromise), 1)
                })
            })

            // lottieAnim.addEventListener('complete', () => {
            //     if(this.options['lottie-preloader'] != undefined)
            //         $document.triggerHandler({type: 'preloaderAnimDone' })
            // })

            lottieAnim.addEventListener('enterFrame', (e) => {
                if(this.options['lottie-preloader'] != undefined) {
                    if(e.currentTime / e.totalTime > 0.8) {
                        $document.triggerHandler({type: 'preloaderAnimDone' })
                        TweenMax.to(this.container, .5, { opacity: 0 });
                    }
                }
            })

            return lottiePromise
        }).catch((err) => {
            console.error(err)
        });

        // requieres window.lottiePromises = [] in globals.js
        window.lottiePromises.push(this.lottiePromise)
    }

    /**
     * This function fetches (in a promise) the data of the given animation (found by it's name)
     * By doing so, it also re-maps the assets URLs to match the good folder
     *
     * @param {string} name - The filename of the animation to load (with extension)
     * @returns {Promise}
     */
    loadAnimationByName(name) {
        return new Promise((resolve, reject) => {
            fetch(PATH_TO_MOTION_FILES+name+'.json') // get the json
             .then((resp) => resp.json()) // parse it
             .then(data => {
                // re-map all assets URLs to match the good folder
                data.assets.map(item => {
                    if(item.u && item.u.length) {
                        item.u = PATH_TO_MOTION_FILES+item.u;
                    }
                    return item;
                })

                // return the data
                resolve(data);
            })
            .catch((err) => {
                reject(err);
            })
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);

        this.toDestroy = true

        let actualDestroy = () => {
            if(this.animation && this.animation.lottie && this.animation.lottie.destroy)
                this.animation.lottie.destroy()
            this.animation.lottie = null
        }
    }
}
