import TransitionManager from './transitions/TransitionManager';
import svg4everybody from 'svg4everybody';
import Preload from "./preload/Preload";
import Popup from "./popup/Popup";
import { $html } from './utils/environment';

export default function(firstBlood) {
    window.lottiePromises = [];
    svg4everybody();

    // Browser detection
    // ==========================================================================
    let isMobile = bowser.mobile || bowser.tablet || false;
    window.isMobile = isMobile;
    if (isMobile) {
        $html.addClass('is-mobile');
    }

    let isEdge = bowser.msedge;
    window.isEdge = isEdge;
    if (isEdge) {
        $html.addClass('is-edge');
    }

    let isFirefox = bowser.firefox;
    window.isFirefox = isFirefox;
    if (isFirefox) {
        $html.addClass('is-firefox');
    }

    let iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if(iOS) {
        $html.addClass('is-ios');
    }

    let isIE = bowser.msie;
    window.isIE = isIE;
    if (isIE) {
        $html.addClass('is-ie');
    }

    if (firstBlood) {
        const transitionManager = new TransitionManager();
        const preload = new Preload();
        const popup = new Popup();
    }
}
