import {$document, APP_NAME} from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Slider';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    TRIGGER: `trigger.${MODULE_NAME}`
}

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        let pagination = this.el.querySelector('.c-slider_pagination');
        let then = this;

        this.$el.on(EVENT.TRIGGER, () => {
            this.slider.params.autoplay.delay = 7000
            this.slider.autoplay.start()
        })

        this.slider = new Swiper(this.el, {
            speed: 1200,
            loop: true,
            slidesPerView: 1,
            simulateTouch: true,
            grabCursor: true,
            pagination: {
                el: pagination,
                clickable: true,
                bulletClass: 'c-slider_bullet'
            },
            breakpoints: {
                700: {
                    spaceBetween: 20
                }
            }
        });
    }

    destroy() {
        super.destroy();
        this.slider.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
