import { APP_NAME, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';
import { Event as SCROLL_EVENT } from './Scroll'

const MODULE_NAME = 'Footnote';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        this.$el.on(EVENT.CLICK, (e) => {
            e.preventDefault()

            if(window.isMobile) {
                this.$scrollContainer = $document
            } else {
                this.$scrollContainer = $('.o-scroll')
            }

            this.$scrollContainer.triggerHandler({
                type: SCROLL_EVENT.SCROLLTO,
                options : {
                    targetElem: $(e.currentTarget.hash)
                }
            })

            $('.c-footnote').addClass('is-active')

            TweenMax.to($('.c-footnote .c-faq-min_bottom')[0], 0.5, { height: $('.c-faq-min_bottom')[0].children[0].offsetHeight })
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
