import {$document, APP_NAME, $window} from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'CardCall';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    MOUSEENTER: `mouseenter.${EVENT_NAMESPACE}`,
    MOUSELEAVE: `mouseleave.${EVENT_NAMESPACE}`,
    CLICK: `click.${EVENT_NAMESPACE}`,
    RESIZE: `resize.${EVENT_NAMESPACE}`
};

const SELECTOR = {
    ACCORDION_MASTER: `.js-accordion-master`,
    ACCORDION_CONTENT: `.js-accordion-content`,
    ACCORDION_BUTTON: `.js-accordion-button`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        this.$card = this.$el.find('.c-card-call')

        this.$card.on(EVENT.MOUSEENTER, (event) => {
            if(!window.isMobile) {
                let $el = $(event.currentTarget)

                this.enter($el);
            }
        })
        this.$card.on(EVENT.MOUSELEAVE, (event) => {
            if(!window.isMobile) {
               let $el = $(event.currentTarget)

               this.leave($el);
           }
        });

        this.$el.on(EVENT.CLICK, SELECTOR.ACCORDION_MASTER, (event) => {
            event.preventDefault();

            let $content = $(event.currentTarget).find(SELECTOR.ACCORDION_CONTENT)
            let contentHeight = $content[0].children[0].offsetHeight;

            if($(event.currentTarget).hasClass('is-active')) {
                $(event.currentTarget).removeClass('is-active')

                TweenMax.to($content, 0.5, { maxHeight: 0 })
            } else {
                $(event.currentTarget).addClass('is-active')

                TweenMax.to($content, 0.5, { maxHeight: contentHeight })
            }
        })

        $window.on(EVENT.RESIZE, () => this.handleResize())
    }

    enter($el) {
        $el.addClass('is-hover');
    }

    leave($el) {
        $el.removeClass('is-hover');
    }

    /**
     * Clear everyting on resize
     */
    handleResize() {
        $(SELECTOR.ACCORDION_MASTER).removeClass('is-active')
        TweenMax.set(this.$el.find(SELECTOR.ACCORDION_CONTENT), { clearProps: 'maxHeight' })
    }

    destroy() {
        super.destroy();
        this.$card.off(`.${EVENT_NAMESPACE}`);
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $window.off(`.${EVENT_NAMESPACE}`);
    }
}
