import { APP_NAME, $window } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'SplitText';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    RESIZE: `resize.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        $window.on(EVENT.RESIZE, () => {
            if(this.split) {
                this.split.revert()
            }

            this.initializeSplit()
        })
    }

    init() {
        this.initializeSplit()
    }

    initializeSplit() {
        this.split = new SplitText(this.el, {
            type:'words, lines',
            linesClass: 'line'
        });
    }

    destroy() {
        super.destroy();
        $window.off(`.${EVENT_NAMESPACE}`);
    }
}
