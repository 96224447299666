/* jshint esnext: true */
import { APP_NAME, $document, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'UIAccent';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    TRIGGER: `trigger.${MODULE_NAME}`,
};

const CLASS = {
    UIACCENT: `has-ui-accent`
}

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        // Declaration of properties

        $document.on(EVENT.TRIGGER, (e) => {
            if(e.way == 'enter') {
                $html.addClass(CLASS.UIACCENT);
            } else {
                $html.removeClass(CLASS.UIACCENT);
            }
        })
    }

    init() {
        // Set events and such

    }

    destroy() {
        super.destroy();
        $document.off(`.${EVENT_NAMESPACE}`);
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
