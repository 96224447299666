import {$document, $window, APP_NAME} from '../utils/environment';
import AbstractModule from './AbstractModule';
import { offset } from '../utils/offset';

const MODULE_NAME = 'TextMask';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    RESIZE: `resize.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        this.textMaskMainRight;
        this.textMaskMainLeft;
        this.textMask;
        this.wrapper;

        this.width = window.innerWidth;
        this.height = window.innerHeight;

        $window.on(EVENT.RESIZE,(event) => {
            this.width = window.innerWidth;
            this.height = window.innerHeight;

            this.resize();
        });
    }

    init() {
        this.textMaskMainRight = this.el.querySelector('.js-text-mask_main-right');
        this.textMaskMainLeft = this.el.querySelector('.js-text-mask_main-left');
        this.textMask = this.el.querySelector('.js-text-mask');
        this.wrapper = this.el.querySelector('.js-text-mask_wrapper');

        this.resize();
    }

    resize() {
        this.textMask.style.position = "absolute";
        this.textMask.style.margin = "0";

        if (this.textMaskMainRight) {
            this.textMask.style.top = offset(this.textMaskMainRight).top - offset(this.wrapper).top + 'px';
            this.textMask.style.right = this.width - offset(this.textMaskMainRight).left - this.textMask.offsetWidth + 'px';
        }
        if (this.textMaskMainLeft) {
            this.textMask.style.top = offset(this.textMaskMainLeft).top - offset(this.wrapper).top + 'px';
            this.textMask.style.left = offset(this.textMaskMainLeft).left + 'px';
        }
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $window.off(`.${EVENT_NAMESPACE}`);
    }
}
