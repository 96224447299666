import {APP_NAME, $body, $html, $document} from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME     = 'CustomerInvite';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK:  `click.${EVENT_NAMESPACE}`,
    SUBMIT: `submit.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
        this.$feedback = this.$el.find('.js-feedback');
    }

    init() {
        this.$el.on(EVENT.SUBMIT, (e) => {
            e.preventDefault();

            this.disableForm();

            var data =  {
                "email":        this.$el.find('[name=email]').val(),
                "firstname":    this.$el.find('[name=firstname]').val(),
                "lastname":     this.$el.find('[name=lastname]').val(),
                "phone":        this.$el.find('[name=tel]').val()
            };

            $.post(window.location.protocol + '//' + window.location.hostname + '/api-customer-invite', {
                data
            }, (response) => {
                this.enableForm();
                this.setFeedback(response.feedback, response.success);
            }, 'json').fail(() => {
                this.enableForm();
                this.setFeedback('An error occurred while posting. Please try again.');
            });
        })
    }

    /**
     * While the form is submitting
     * Could be used to add a gif spinner
     */
    disableForm() {
        this.$el.addClass('-disabled');
        this.$el.find('[type=submit]').attr('disabled', 'disabled');
    }

    /**
     * When the form is not submitting
     */
    enableForm() {
        this.$el.removeClass('-disabled');
        this.$el.find('[type=submit]').removeAttr('disabled');
    }

    /**
     * Display feedback over the form
     *
     * @param feedback
     */
    setFeedback(feedback, success) {
        this.$feedback.find('p').html(feedback);

        if(success) {
            // this.$el.find('.c-invite-lightbox_form_input').hide()
            // this.$el.find('.c-invite-lightbox_button').hide()

            $html.addClass('has-invite-success');

            if(window.gtag) {
                gtag('event', 'conversion', {
                    'send_to': 'AW-778099821/odmFCO_3u5ABEO24g_MC'
                });
            }

            if(window.fbq) {
                fbq('track', 'Lead');
            }
        }
    }

    /**
     * Remove listeners, destroy module.
     */
    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
