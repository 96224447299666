/* global _gaq: true, ga: true */

var defaultSwitches = require("./switches")

module.exports = function(options) {
  options = options || {}
  options.elements = options.elements || "a[href], form[action]"
  options.selectors = options.selectors || ["title", ".js-Pjax"]
  options.switches = options.switches || {}
  options.switchesOptions = options.switchesOptions || {}
  options.history = (typeof options.history === "undefined") ? true : options.history
  options.analytics = (typeof options.analytics === "function" || options.analytics === false) ? options.analytics : defaultAnalytics
  options.scrollTo = (typeof options.scrollTo === "undefined") ? 0 : options.scrollTo
  options.scrollRestoration = (typeof options.scrollRestoration !== "undefined") ? options.scrollRestoration : true
  options.cacheBust = (typeof options.cacheBust === "undefined") ? true : options.cacheBust
  options.debug = options.debug || false
  options.timeout = options.timeout || 0
  options.currentUrlFullReload = (typeof options.currentUrlFullReload === "undefined") ? false : options.currentUrlFullReload

  // We can’t replace body.outerHTML or head.outerHTML.
  // It creates a bug where a new body or head are created in the DOM.
  // If you set head.outerHTML, a new body tag is appended, so the DOM has 2 body nodes, and vice versa
  if (!options.switches.head) {
    options.switches.head = defaultSwitches.switchElementsAlt
  }
  if (!options.switches.body) {
    options.switches.body = defaultSwitches.switchElementsAlt
  }

  return options
}

/* istanbul ignore next */
function defaultAnalytics() {
  if (window._gaq) {
    _gaq.push(["_trackPageview"])
  }
  if (window.ga) {
    ga("send", "pageview", {page: location.pathname, title: document.title})
  }
}
