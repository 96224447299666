import { APP_NAME, $document, $html, $body,  isDebug } from '../utils/environment';
import { EVENT as APP_EVENT } from '../app';

const MODULE_NAME = 'Popup';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLOSE: `close.${MODULE_NAME}`
}

export default class {
    constructor(options) {
        this.popupItems = {};
        this.popupBtnItems = {}

        this.popups = document.querySelectorAll('.js-popup');

        $document.on(EVENT.CLOSE, () => this.close())

        for (let i = 0; i < this.popups.length; i++) {
            let close = this.popups[i].querySelector('.c-popups_close');
            close.addEventListener('click', () => {
                this.close(this.popups[i].dataset.popup)
            });
            this.popupItems[this.popups[i].dataset.popup] = {
                element: this.popups[i],
                active: false
            }
        }

        this.btns = document.querySelectorAll('.js-popup-btn');
        for (let i = 0; i < this.btns.length; i++) {
            this.popupBtnItems[this.btns[i].dataset.popup] = {
                element: this.btns[i],
                active: false
            };
            this.btns[i].addEventListener('click', () => {
                this.active(this.btns[i].dataset.popup)
            })
        }
    }

    active(id) {
        if (!this.popupBtnItems[id].active) {
            for (let key in this.popupBtnItems) {
                if (id !== key) {
                    this.popupBtnItems[key].active = false;
                    this.popupItems[key].active = false;
                    this.popupItems[key].element.classList.remove('is-active');
                }
            }
            this.popupBtnItems[id].active = true;
            this.popupItems[id].active = true;
            this.popupItems[id].element.classList.add('is-active');
        }
    }
    close() {
        for (let key in this.popupBtnItems) {
            this.popupBtnItems[key].active = false;
            this.popupItems[key].active = false;
            this.popupItems[key].element.classList.remove('is-active');
        }
    }
}
