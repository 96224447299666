import { APP_NAME, $document, $html, $body,  isDebug } from '../utils/environment';
import { EVENT as APP_EVENT } from '../app';

const MODULE_NAME = 'Preload';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    READYTOREMOVE: `readyToRemove.${EVENT_NAMESPACE}`,
    READYTODESTROY: `readyToDestroy.${EVENT_NAMESPACE}`,
    GOTO: `goto.${EVENT_NAMESPACE}`
};

export default class {
    constructor(options) {
        this.preloader = document.querySelector('.js-preloader');

        // document.addEventListener('pjax:send',() => this.preloaderActivate());

        // $document.on(EVENT.READYTOREMOVE,(event) => {
        //     this.remove(event.oldView, event.newView);
        // });
        // $document.on(EVENT.READYTODESTROY,(event) => {
        //     this.reinit();
        // });
        // $document.on(EVENT.GOTO, (e) => {
        //     if(e.options.el != undefined) {
        //         this.autoEl = e.options.el.get(0);
        //     }
        //     this.pjax.loadUrl(e.options.link, $.extend({}, this.pjax.options));
        // });

        this.preload();

        let lottiePromise = new Promise((resolve) => {
            $document.on('preloaderAnimDone', resolve)
        })

        let diactivatePromise = new Promise((resolve) => {
            $document.on('preloader-diactivate', resolve);
        })

        Promise.all([diactivatePromise,lottiePromise]).then(() => {
            this.preloaderDiactivate()
        })
    }

    preloaderActivate() {
        TweenMax.to(this.preloader, 1.2, {
            ease: Quint.easeOut,
            y: "-100%"
        })
    }
    preloaderDiactivate() {
        $document.triggerHandler({
            type: 'show-init'
        });
        setTimeout(() => {
            $document.triggerHandler({
                type: 'show-start'
            });
        }, 500);
        TweenMax.to(this.preloader, 1.2, {
            ease: Power4.easeInOut,
            y: "-100%",
            onComplete: () => {
                TweenMax.set(this.preloader, {
                    display: 'none'
                });
            }
        })
    }

    preload() {
        this.preloadImg = document.querySelectorAll('.js-preload-img');
        this.length = this.preloadImg.length;
        if (this.length === 0) {
            $document.triggerHandler({
                type: 'preload-img'
            });
        }
        this.preloadCounter = 0;

        for (let i = 0; i < this.preloadImg.length; i++) {
            this.loading(this.preloadImg[i])
        }
    }
    loading(element) {
        let img = new Image;
        img.src = element.style.backgroundImage.slice(4, -1).replace(/"/g, "");
        img.addEventListener('load', () => {
            this.isLoad();
        });

        function isLoad() {
            this.preloadCounter++;
            if (this.preloadCounter >= this.length) {
                $document.triggerHandler({
                    type: 'preload-img'
                });
            }
        }
    }
}
