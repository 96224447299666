import { APP_NAME, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'UIToggler';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    TOGGLE: `toggle.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        this.$el.on(EVENT.TOGGLE, () => {
            $html.toggleClass('ui-white')
        })
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $html.removeClass('ui-white')
    }
}
