import {$document, APP_NAME} from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'SliderArticle';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        this.$slide = this.$el.find('.swiper-slide');

        if(this.$slide.length > 1) {
            this.slider = new Swiper(this.el, {
                speed: 700,
                loop: true,
                slidesPerView: "auto",
                grabCursor: this.infinite,
                autoHeight: true,
                centeredSlides: true,
                spaceBetween: 40,
                breakpoints: {
                    700: {
                        spaceBetween: 20
                    }
                }
            });
        }
    }

    destroy() {
        super.destroy();
        if(this.slider && this.slider.destroy()) this.slider.destroy(true, true);
        this.$el.off(`.${EVENT_NAMESPACE}`);
    }
}
