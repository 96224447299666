import { APP_NAME, $document, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';
import LocomotiveScroll from './ScrollManager';

const MODULE_NAME = 'LocomotiveScroll';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {

        setTimeout(() => {
            // Init Locomotive Scroll
            this.scroll = new LocomotiveScroll({
                container: $('#js-scroll'),
                selector: '.js-animate',
                smooth: true,
                smoothMobile: false,
                getWay: true
            });
        }, 300);

    }

    destroy() {
        super.destroy();
        this.scroll.destroy();
        $html.removeClass('has-scrolled')
    }
}
