/* jshint esnext: true */
import { APP_NAME, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'FooterLink';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    MOUSEENTER: `mouseenter.${EVENT_NAMESPACE}`,
    MOUSELEAVE: `mouseleave.${EVENT_NAMESPACE}`,
    HIDESCROLLTOP: `hideScrollTop.${EVENT_NAMESPACE}`
};

const CLASS = {
    HASHOVER: 'has-hover'
}

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {

        this.$el.on(EVENT.MOUSEENTER, '.js-link',(e) => {
            this.$el.addClass(CLASS.HASHOVER);
        });
        this.$el.on(EVENT.MOUSELEAVE, '.js-link',(e) => {
            this.$el.removeClass(CLASS.HASHOVER);
        });

        this.$el.on(EVENT.HIDESCROLLTOP, () => { $html.toggleClass('hide-scroll-top') })

    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $html.toggleClass('hide-scroll-top', false)
    }
}
