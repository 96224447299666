import { $document, APP_NAME, $body, $html } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'VideoLightbox';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`
};

const Selector = {
    INNER: '.js-embed-inner'
};

const Class = {
    OPEN: 'popup-video-is-open'
};

export default class extends AbstractModule {
    /**
     * Constructor.
     *
     * @return {void}
     */
    constructor(options) {
        super(options)

        this.isPopup = (options.popup === true);
        this.popup = {
            iframe: options.iframe,
            client: options.client,
            title:  options.title,
            provider: options.provider
        };

        console.log(this.popup.iframe)

    }

    /**
     * Initialize dom and events.
     *
     * @return {void}
     */
    init() {
        this.$inner = $(Selector.INNER, this.$el);

        this.$popup = $('.js-popup-video');
        this.$popupInner = $('.js-popup-video-inner', this.$popup);
        this.$popupEmbed = $('.js-popup-video-embed', this.$popup);

        this.$popupClient = $('.js-popup-video-client', this.$popup);
        this.$popupTitle  = $('.js-popup-video-title',  this.$popup);

        this.$el.on(EVENT.CLICK, Selector.INNER, (event) => {
            event.preventDefault();

            this.play();
        });

        $document.on(EVENT.CLICK, '.js-popup-close', (event) => {
            event.preventDefault();

            this.close();
        });
    }

    /**
     * Play a video according to module options.
     *
     * @return {void}
     */
    play() {

        if (this.isPopup) {
            $html.addClass(Class.OPEN);

            let src = $(this.popup.iframe).attr('src');
            let $iframe = $(this.popup.iframe);

            if(this.popup.provider === 'vimeo') {
                $($iframe).attr('src',`${src}?autoplay=1`);
            }
            else if (this.popup.provider === 'youtube') {
                $($iframe).attr('src',`${src}?rel=0&autoplay=1`);
            }

            this.$popupEmbed.html($iframe);
            this.$popupClient.html(this.popup.client);
            this.$popupTitle.html(this.popup.title);

        } else {
            this.$inner.html(`${this.popup.iframe}`);
            this.$el.addClass('is-playing');
        }
    }

    /**
     * Event destroy popup and close iframe
     *
     * @return {void}
     */
    close() {
        $html.removeClass(Class.OPEN);

        setTimeout(() => {
            this.$popupEmbed.html('');
        }, 1000)
    }

    /**
     * Destroy.
     *
     * @return {void}
     */
    destroy() {
        $document.off(EVENT_NAMESPACE);
        this.$el.off(EVENT_NAMESPACE);
    }
}
