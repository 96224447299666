import {$document, APP_NAME} from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'Cover';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    RESIZE: `resize.${EVENT_NAMESPACE}`,
    CLICK: `click.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);

        $document.on(EVENT.RESIZE,(event) => {
            this.resize();
        });
    }

    init() {
       this.$btn = $(this.el);
       this.active = false;
       this.$btn.on(EVENT.CLICK, () => {
            if (!this.$el.hasClass('is-active')) {
                this.active = true;
                this.el.classList.add('is-active');
                TweenMax.to(this.bottom, 0.5, {
                    height: this.bottomHeight
                })
            } else {
                this.active = false;
                this.el.classList.remove('is-active');
                TweenMax.to(this.bottom, 0.5, {
                    height: 0
                })
            }
       });
       this.bottom = this.el.querySelector('.c-faq-min_bottom');
       this.resize();
    }

    resize() {
        this.bottomHeight = this.bottom.children[0].offsetHeight;
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        this.$btn.off(`.${EVENT_NAMESPACE}`);
        $document.off(`.${EVENT_NAMESPACE}`);
    }
}
