import { APP_NAME, $body, $html, $document } from '../utils/environment';
import AbstractModule from './AbstractModule';

const MODULE_NAME = 'LightboxInvite';
const EVENT_NAMESPACE = `${APP_NAME}.${MODULE_NAME}`;

const EVENT = {
    CLICK: `click.${EVENT_NAMESPACE}`,
    KEYUP: `keyup.${EVENT_NAMESPACE}`
};

export default class extends AbstractModule {
    constructor(options) {
        super(options);
    }

    init() {
        $body.on(EVENT.CLICK, '.js-request-invite', (event) => {
            event.preventDefault();
            $html.toggleClass('has-invite-open')

            if(window.gtag) {
                gtag('event', 'open', {
                  'event_category': 'ui.invite-lightbox'
                });
            }
        })

        this.$el.on(EVENT.CLICK, '.js-close', () => { $html.removeClass('has-invite-open') })

        $document.on(EVENT.KEYUP, (e) => {
             if (e.key === "Escape") {
                $html.removeClass('has-invite-open')
            }
        });
    }

    destroy() {
        super.destroy();
        this.$el.off(`.${EVENT_NAMESPACE}`);
        $body.off(`.${EVENT_NAMESPACE}`);
        $document.off(`.${EVENT_NAMESPACE}`);
    }
}
